import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import HeaderMap from '../components/HeaderMap';
import BannerPicture from '../components/BannerPicture';
import CustomersLogos from '../components/CustomersLogos';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import CardCustomer from '../components/CardCustomer';
import ThreeCardsCustomers from '../components/ThreeCardsCustomers';
import BackgroundPicture from '../components/BackgroundPicture';
import SectionOnlyFooter from '../components/SectionOnlyFooter';

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;

const CardCustomerLayout = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    margin: 7% 0%;


    @media (max-width: 767px) {
        flex-wrap:nowrap;
        overflow-x: auto;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
        touch-action: pan-x;
    }
`;

const BgDarkBlue = styled.div`
    position:absolute;
    left:0;
    z-index: -10000;
    background-color: #0c499b;
    width: 100%;
    height: 46%;
`;

const LivemapIframe = styled.div`
    display: flex;
    width: 100%;
    height: 560px;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    box-shadow: 3px 3px 18px 3px rgba(99,99,99,.16863);
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('travelPage');

    const icons = [
        {titleRight: t('DESCRIPTION_RECURRENCE')},
        {
            icon: 'icon-travel1',
            text: t('DESCRIPTION_CITY_GUIDES')
        },
        {
            icon: 'icon-type',
            text: t('DESCRIPTION_LEISURE')
        },
        {
            icon: 'icon-users1',
            text: t('DESCRIPTION_REAL_ESTATE_GUIDES')
        }];

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_TRAVEL')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_TRAVEL')}/>
            </Helmet>
            <Layout>
                <BackgroundPicture
                    image={data.background.childImageSharp}
                    alt='table with computer, map and other travel accessories'
                />
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <CustomersLogos
                        title={t('TITLE_POWER_WEBSITE')}
                        size='small'
                        customersLogos={[
                            {image: '/images/products/wemap-ar/hachette-tourisme.svg'},
                            {image: '/images/products/wemap-web/air-france.svg'},
                            {image: '/images/industries/travel/logo-little-big-road.png'},
                            {image: '/images/industries/travel/logo-visit-marrakech.png'},
                            {image: '/images/industries/travel/logo-otbb.png'},
                            {image: '/images/products/wemap-pro/region-haut-de-france.svg'}
                        ]}
                    />


                    <SectionOnlyFooter
                        titleSection={t('TITLE_USER-CENTERED')}
                        cellParagraph={t('DESCRIPTION_SMART_MAPS_WIDGET')}
                        pictureRight={data.travel.childImageSharp}
                        altDescription='computer with detailed pinpoint map'
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        widthPicture='400px'
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-pencil',
                                text: t('TEXT_TV')
                            },
                            {
                                icon: 'icon-plane',
                                text: t('TEXT_PURE_PLAYERS')
                            },
                            {
                                icon: 'icon-spinner',
                                text: t('TEXT_NATIONAL_NEWS')
                            }
                        ]}
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons title={t('TITLE_BRAND')}
                            text={t('DESCRIPTION_MCC')}
                            titleButton={t('BUTTON_TALK_ABOUT_PROJECT')}
                            icons={icons}
                            leftIcon='/images/industries/travel/two-dots-logo.png'
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <TwoColumnsLayout>
                        <TwoColumnsIcons title={t('TITLE_INCREASE')}
                            text={t('DESCRIPTION_PARLEMENT_TRANSPARENT')}
                            titleButton={t('BUTTON_TALK_ABOUT_PROJECT')}
                            leftIcon='/images/products/wemap-ar/hachette-tourisme.svg'
                            icons={[
                                { titleRight: t('DESCRIPTION_INTERACTIVE') },
                                {
                                    icon: 'icon-embed2',
                                    text: t('DESCRIPTION_NATIONAL_ELECTIONS')
                                },
                                {
                                    icon: 'icon-event_note',
                                    text: t('DESCRIPTION_LOCAL_ELECTIONS')
                                }
                            ]}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <BgDarkBlue></BgDarkBlue>

                    <HeaderMap customerCicleImageUrl='/images/industries/travel/icon_circle_af.png'
                        by={t('TEXT_BY')}
                        titleCaseStudy={t('Case Study')}
                        title={t('TITLE_FRANCE3')}
                        text={t('TITLE_DESCRIPTION_FRANCE3')}
                        textPointInfos={t('TITLE_POINTS_FRANCE3')}
                        textViewsInfos={t('TITLE_VIEWS_FRANCE3')}
                        descriptionCustomer={t('TITLE_FRANCE3_DESCRIPTION_MAP')}
                        infosRight1={t('TITLE_DESCRIPTION_RIGHT_AF_0')}
                        infosRight2={t('TITLE_DESCRIPTION_RIGHT_AF_1')}
                        infosRight3={t('TITLE_DESCRIPTION_RIGHT_AF_2')}
                        infosRight4={t('TITLE_DESCRIPTION_RIGHT_AF_3')} />

                    <LivemapIframe>
                        <iframe title="livemap"
                            width="100%"
                            height="100%"
                            src="https://livemap.getwemap.com/iframe.php?emmid=4167#/search@0.0000000,-3.0919106,2.24"
                            allowfullscreen
                            frameborder="0"></iframe>
                    </LivemapIframe>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_PERSONALIZED')}
                        pictureRight={data.travelMap.childImageSharp}
                        altDescription='computer view with Wemap map of sport club'
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        widthPicture='400px'
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-search2',
                                text: t('TITLE_SHOWCASE')
                            },
                            {
                                icon: 'icon-location-current',
                                text: t('TITLE_INDOOR_OUTDOOR')
                            },
                            {
                                icon: 'icon-tablet_mac',
                                text: t('TITLE_MAP_WEBSITE')
                            }
                        ]}
                    />

                    <ThreeCardsCustomers
                        url='https://www.guides-hachette.fr/un-grand-week-end'
                        title={t('UGWEP')}
                        descriptionCustomer={t('UGWEP_DESCRIPTION')}
                        LinkApp='https://itunes.apple.com/fr/app/un-grand-week-end/id1444114745?mt=8'
                        LinkApp2='https://play.google.com/store/apps/details?id=com.onprint.ugwe'
                        textApp={t('APP_IOS')}
                        textApp2={t('APP_ANDROID')}
                        textButton={t('LEARN_MORE')}
                        pictureCard1='/images/industries/travel/wemap-hachette-tourisme.png'
                        altPicture1='View of buildings and street'
                        pictureCard2='/images/industries/travel/carte-wemap.png'
                        altPicture2='Close up of Wemap map'
                        pictureCard3='/images/industries/travel/un-grand-week-end-paris.png'
                        altPicture3='Logo of Un Grand Weekend'
                        titleCard1={t('TEXT_ADDRESS')}
                        titleCard2={t('WEMAP_MAP')}
                        titleCard3={t('APP_UGWE')}
                    />

                    <hr className='line' />

                    <CardCustomerLayout>

                        <CardCustomer
                            url='https://livemap.getwemap.com/dom.html?emmid=21534&token=U14QEQTODS2CEH2II9FE3R8J1'
                            picturecard={data.sncf.childImageSharp}
                            altPictureCard='SNCF logo'
                            title={t('TITLE_SNCF')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_SNCF_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_SNCF_POINT'),
                                    infoView: t('TITLE_SNCF_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.mulhouse-alsace.fr/mobilites/randonnees-pedestres/'
                            picturecard={data.mulhouse.childImageSharp}
                            altPictureCard='Mulhouse logo'
                            title={t('TITLE_MULHOUSE')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_MULHOUSE_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_MULHOUSE_POINT'),
                                    infoView: t('TITLE_MULHOUSE_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.tourisme-en-hautsdefrance.com/decouvrir/les-departements/le-pas-de-calais/'
                            picturecard={data.hautfrance.childImageSharp}
                            altPictureCard='Haut de France logo'
                            title={t('TITLE_HAUT_FRANCE')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_HAUT_FRANCE_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_HAUT_FRANCE_POINT'),
                                    infoView: t('TITLE_HAUT_FRANCE_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.routard.com/contenu-dossier/cid133713-le-routard-grand-guerre-14-18-les-chemins-de-memoire.html?page=2'
                            picturecard={data.routard.childImageSharp}
                            altPictureCard='Guide de Routard logo'
                            title={t('TITLE_ROUTARD')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_ROUTARD_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_ROUTARD_POINT'),
                                    infoView: t('TITLE_ROUTARD_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.generationmontagne.com/'
                            picturecard={data.ara.childImageSharp}
                            altPictureCard='Auvergne Rhône Alpes Tourisme logo'
                            title={t('TITLE_AUVERGNE')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_AUVERGNE_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_AUVERGNE_POINT'),
                                    infoView: t('TITLE_AUVERGNE_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://insidr.co/best-day-trips-outside-bordeaux/'
                            picturecard={data.insidr.childImageSharp}
                            altPictureCard='Insidr logo'
                            title={t('TITLE_INSIDR')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_INSIDR_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_INSIDR_POINT'),
                                    infoView: t('TITLE_INSIDR_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://getwemap.com/proxima'
                            picturecard={data.proxima.childImageSharp}
                            altPictureCard='Proxima logo'
                            title={t('TITLE_PROXIMA')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_PROXIMA_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_PROXIMA_POINT'),
                                    infoView: t('TITLE_PROXIMA_VIEW')
                                }
                            ]}
                        />
                    </CardCustomerLayout>

                    <BannerPicture backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with people traveling in jungle'
                        titleBannerPicture={t('TITLE_BANNER_CASE_STUDIES')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/' />

                </div>
            </Layout>
        </React.Fragment>
    );
};
IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  background: file(relativePath: {eq: "background-image/travel-bg.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  banner: file(relativePath: {eq: "industries/travel/wemap_picture_travel.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  hautfrance: file(
    relativePath: {eq: "industries/travel/picture_haut_de_france.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  sncf: file(relativePath: {eq: "industries/travel/card-sncf.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  mulhouse: file(relativePath: {eq: "industries/travel/picture_mulhouse.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  routard: file(relativePath: {eq: "industries/travel/picture_guideroutard.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  ara: file(
    relativePath: {eq: "industries/travel/picture_auvergne-rhones-alpes.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  insidr: file(relativePath: {eq: "industries/travel/picture_insidr.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  proxima: file(relativePath: {eq: "industries/travel/picture_proxima.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  travel: file(relativePath: {eq: "industries/travel/wemap-travel.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 550, layout: FIXED)
    }
  }
  travelMap: file(relativePath: {eq: "industries/travel/map-hachette-mobile.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 470, layout: FIXED)
    }
  }
  uniqueHeritages: file(
    relativePath: {eq: "industries/culture/wemap-unique-heritages.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, height: 470, layout: FIXED)
    }
  }
}
`;
